import styled from "styled-components"
import Container from "@material-ui/core/Container"
import CustomImage from "../../components/custom-image/custom-image.component"
import { keyframes } from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  &.masked {
    height: 90vh;
    margin-top: -10px;
    clip-path: url("#header-mask");
    ${({ theme }) => theme.breakpoints.down("xs")} {
      clip-path: url(#header-mask-mobile);
    }
  }

  ${props =>
    props.scrollSnap
      ? `scroll-snap-align: start;
      scroll-snap-stop: always;`
      : ""}

  &.text-blue {
    h1,
    p {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    // height: 100vh;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100px;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), transparent);
  }
`

export const FloatingContainer = styled(Container)`
  position: absolute;
  z-index: 10;
  top: var(--header-height);
  left: 50%;
  transform: translateX(-50%);
  height: calc(80% - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  // padding-left: 169px;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    transform: none;
    left: 0;
    width: 75%;
  }
`

export const Video = styled.video`
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
`
export const Image = styled(CustomImage)`
  min-width: 100%;
  height: 100%;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    height: 100%;
  }

  .gatsby-image-wrapper img {
    object-position: right;
  }
`

export const ContentArea = styled.div`
  max-width: 565px;
  color: #fff;

  h1 {
    font-weight: 400;
    font-size: clamp(2.5rem, 7vw, 4.375rem);
    line-height: initial;
    ${({ theme }) => theme.breakpoints.down("xs")} {
      font-size: 3rem;
      line-height: 1em;
      margin-bottom: 30px;
    }
  }

  span {
    display: block;
  }

  sup {
    font-size: 0.5em;
    top: -0.1em;
  }
`
export const Content = styled.div`
  font-weight: 400;
  text-transform: uppercase;
  p {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: clamp(1.1rem, 2vw, 1.3125rem);
  }
  ${({ theme }) => theme.breakpoints.down("xs")} {
    p {
      font-size: 1.15rem;
    }
  }
`

const bounceAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-35px); }
  100% { transform: translateY(0); } 
`

export const Arrow = styled.div`
  position: absolute;
  left: calc(50% - 15px);
  bottom: 10vh;
  z-index: 8;
  max-width: 50px;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: ${bounceAnimation};
  animation-timing-function: linear;
  width: 30px;

  svg {
    width: 30px;
    path {
      fill: #fff;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    // display: none;
  }
`
